.sidebar-drawer
    .ant-drawer-content-wrapper
        width: auto !important
        .ant-drawer-body
            padding: 1rem 0
            background-color: #F5F8FA
.sidebar-body
    width: 250px
    padding: 1rem
    padding-top: 0
    height: 100%
    display: flex
    flex-direction: column
    .main-content
        flex-grow: 1
        height: 1%
        overflow: hidden
        .user-info-wrapper
            display: flex
            align-items: center
            .user-img
                width: 35px
                height: 35px
                border-radius: 50%
                object-fit: cover
        .nav-links-wrapper
            .sidebar-items-list
                padding: 0
                list-style-type: none
                .sidebar-item
                    padding: 0.2rem 0rem
                    .sidebar-link
                        display: flex
                        align-items: center
                        color: #4D4F5C
                        text-decoration: none !important
                        border-radius: 20px
                        background-color: transparent
                        padding: 0rem 0.75rem
                        transition: all 250ms
                        border: 2px solid transparent
                        .link-icon
                            width: 30px
                            height: 35px
                            display: flex
                            justify-content: center
                            align-items: center
                            svg
                                max-width: 85% !important
                                max-height: 55% !important
                                path
                                    fill: #4D4F5C !important
                                    stroke: #4D4F5C
                                    transition: all 250ms
                        &.logout-btn
                            color: #C33232
                            cursor: pointer
                            user-select: none
                            .link-icon
                                svg
                                    path
                                        fill: #C33232
                        &.router-link:hover,&.router-link:focus
                            background-color: rgba($theme-color,0.8)
                            color: white
                            border: 2px solid rgba($theme-color,0.8)
                            .link-icon
                                svg
                                    path
                                        fill: white
                                        stroke: white
                        &.router-link.active
                            background-color: $theme-color
                            color: white
                            .link-icon
                                svg
                                    path
                                        fill: white !important
                                        stroke: white
@media (min-width: 1500px)
    .sidebar-body
        width: 300px
@media (max-width: 991px)
    .sidebar-drawer
        .ant-drawer-content-wrapper
            .ant-drawer-body
                padding: 0.5rem 0
    .sidebar-body
        width: 230px
        padding: 0.5rem
        padding-top: 0
@media (max-width: 767px)
    .sidebar-body
        .main-content
            .user-info-wrapper
                .user-img
                    width: 30px
                    height: 30px
