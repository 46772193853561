.btn-rounded
    border-radius: 20px !important
.btn
    display: inline-block
    text-decoration: none !important
    outline: none !important
    padding: 0.5rem 1.5rem 
    box-shadow: 0px 0px 0px !important
    transition: all 250ms
    cursor: pointer
    font-weight: 600
    text-align: center
    user-select: none
    border-radius: 10px
.btn-transparent
    background-color: transparent
    border: none
    outline: none !important
.btn-white
    color: $theme-color !important
    background-color: white !important
    border: none
    &:hover,&:focus
        color: $theme-color !important
        background-color: darken(white,2%) !important
    &:active
        color: $theme-color !important
        background-color: darken(white,8%) !important
.btn-theme-color
    color: white !important
    background-color: $theme-color !important
    &:hover,&:focus
        color: white !important
        background-color: darken($theme-color,5%) !important
    &:active
        color: white !important
        background-color: darken($theme-color,15%) !important

.btn-theme-color-outline
    color: $theme-color !important
    background-color: transparent !important
    border: 1px solid $theme-color !important
    &:hover,&:focus
        background-color: $theme-color !important
        color: white !important
    &:active
        color: $theme-color !important
        background-color: rgba($theme-color,0.1) !important
