.navbar
    display: flex
    justify-content: space-between
    align-items: center
    .logo-wrapper
        img
            width: 40px
@media (max-width: 991px)
    .navbar
        .logo-wrapper
            img
                width: 35px