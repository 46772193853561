.link-card
    text-decoration: none !important
    color: unset !important
.white-card
    background-color: white
    padding: 1rem 1.5rem
    border-radius: 10px
.white-border-card
    display: block
    background-color: white
    padding: 0.25rem 0.5rem
    border: 0.5px solid rgba(177, 182, 192, 0.5)
    border-radius: 5px
.shadow-card
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16) !important
.bordered-theme-color
    border: 1px solid $theme-color
.chart-wrapper.position-relative
    .white-shadow-filter
        position: absolute
        top: 0
        height: 100%
        width: 100%
        pointer-events: none
        box-shadow: inset 0px -11px 10px rgba(white,0.5)
        &.white-shadow-filter-big
            box-shadow: inset -15px -104px 85px rgba(white,0.25)

    .percentage-wrapper
        position: absolute
        top: 50%
        left: 50.2%
        transform: translate(-50%,-50%)
        background-color: white
        border-radius: 50%
        width: 45px
        height: 45px
        display: flex
        justify-content: center
        align-items: center
        pointer-events: none
        span
            white-space: nowrap
.transparent-card
    .header
        padding-left: 0.25rem
        padding-right: 0.25rem
        padding-bottom: 0.25rem
        border-bottom: 2px solid $gray-400
    .body
        padding: 0.75rem 0.75rem

@media (max-width: 600px)
    .white-card
        padding: 0.5rem 1rem
@media (max-width: 420px)
    .white-card
        padding: 0.5rem 2rem
