html {
  .rtl {
    direction: rtl;
    text-align: right;
    .form-wrapper
      .form
      .form-input-wrapper
      .input-wrapper
      .react-phone-number-input__row
      input,
    .form-wrapper .form .form-input-wrapper .input-wrapper .PhoneInput input,
    .form-wrapper
      .form
      .form-input-wrapper
      .input-wrapper
      .react-tel-input
      input {
      padding-right: 40px;
      padding-left: 10px;
      direction: ltr;
      text-align: right;
    }

    .form-wrapper
      .form
      .form-input-wrapper
      .input-wrapper
      .react-phone-number-input__row
      .selected-flag
      .arrow,
    .form-wrapper
      .form
      .form-input-wrapper
      .input-wrapper
      .PhoneInput
      .selected-flag
      .arrow,
    .form-wrapper
      .form
      .form-input-wrapper
      .input-wrapper
      .react-tel-input
      .selected-flag {
      justify-content: center;
    }

    .form-wrapper
      .form
      .form-input-wrapper
      .input-wrapper
      .react-phone-number-input__row
      .selected-flag
      .arrow,
    .form-wrapper
      .form
      .form-input-wrapper
      .input-wrapper
      .PhoneInput
      .selected-flag
      .arrow,
    .form-wrapper
      .form
      .form-input-wrapper
      .input-wrapper
      .react-tel-input
      .selected-flag
      .arrow {
      left: unset;
      right: 20px;
    }

    .react-phone-number-input__input {
      direction: ltr;
      text-align: right;
    }
  }
}
