html
    min-height: 100%
    body
        font-family: 'Cairo', sans-serif
        font-weight: normal
        font-style: normal
        -webkit-font-smoothing: none
        -webkit-font-smoothing: antialiased
        height: 100vh
        background-color: #F5F8FA
        overflow-x: hidden

        #root
            height: 100%

hr
    // border-color: #EBF2F7
    border: 1px solid #EBF2F7
    margin-bottom: 0.5rem
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link
    display: flex !important
    align-items: center !important
    justify-content: center !important
.ant-message-notice:first-child
    margin-top: 45px
.ant-notification
    z-index: 10000
.phone
    direction: ltr
    text-align: left
    display: inline-block
.user-select-none
    user-select: none
.pointer-events-none
    pointer-events: none
.cursor-pointer
    cursor: pointer
.disabled
    opacity: 0.8
    user-select: none
    pointer-events: none

.modal-wide
    width: 900px !important
.ant-modal-mask
    z-index: 1000
.ant-modal-wrap
    z-index: 1000
.ant-popover
    z-index: 1000
.ant-modal-close
    right: unset
    left: 0
    outline: none !important
.ant-modal-content
    border-radius: 15px

.bold-progress
    .ant-progress-circle
        background-color: white
    .ant-progress-text
        font-size: 0.85rem
        font-weight: 600

.animated.fadeIn
    opacity: 0
    animation: fadeIn 0.4s ease-in-out forwards

@keyframes fadeIn
    from
        opacity: 0
    to
        opacity: 1
