.steps-theme-color-wrapper
    .ant-steps-item-title
        white-space: nowrap
    .ant-steps-item-tail::after
        height: 2px

    .ant-steps-item-icon
        display: flex
        justify-content: center !important
        align-items: center !important
    .ant-steps-item-finish 
        .ant-steps-item-icon
            border-color: $theme-color
            background-color: $theme-color
            .ant-steps-icon
                color: white
        & > .ant-steps-item-container > .ant-steps-item-tail::after
            background-color: $theme-color
    .ant-steps-item-active
        .ant-steps-item-icon
            border: 2px solid
            border-color: $theme-color
            background-color: white
            .ant-steps-icon
                color: $theme-color
                font-weight: 600