.pagination-list-wrapper
    .ant-pagination
        li
            margin: 0rem 0rem
            border-radius: 0
            &.ant-pagination-prev, &.ant-pagination-next
                height: 29px
                a
                    display: flex
                    justify-content: center
                    align-items: center
            &.ant-pagination-item
                background-color: white
                color: $gray-900
                // margin: 0.25rem 0.25rem
                border: 1px solid $gray-400
                height: 29px
                display: inline-flex
                justify-content: center
                align-items: center
                &:hover
                    background-color: rgba($theme-color,0.8)
                    color: white
                &.ant-pagination-item-active
                    background-color: $theme-color
                    border: 1px solid $theme-color
                    color: white