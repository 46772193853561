.tabs_bar
    &.ant-tabs-rtl
        .ant-tabs-nav
            .ant-tabs-nav-wrap
                .ant-tabs-nav-list
                    .ant-tabs-tab
                        margin: 0px 0 0 10px !important
    .ant-tabs-nav
        // background-color: white
        border-radius: 10px
        padding: 0rem 0.5rem
        &::before
            border: none
        .ant-tabs-nav-wrap
            .ant-tabs-nav-list
                .ant-tabs-ink-bar
                    display: none

                .ant-tabs-tab
                    padding: 0
                    .ant-tabs-tab-btn
                        .tab-link
                            display: flex
                            align-items: center
                            text-decoration: none !important
                            border-radius: 20px
                            background-color: #E5E5E5
                            padding: 0.5rem 1rem
                            transition: all 250ms
                            border: 2px solid transparent

                            color: #4D4F5C
                            .link-icon
                                width: 30px
                                height: 35px
                                display: flex
                                justify-content: center
                                align-items: center
                                svg
                                    max-width: 85% !important
                                    max-height: 55% !important
                                    path
                                        fill: #4D4F5C
                                        transition: all 250ms
                    &.ant-tabs-tab-active
                        .ant-tabs-tab-btn
                            .tab-link
                                background-color: $theme-color
                                color: white
                                .link-icon
                                    svg
                                        path
                                            fill: white

.cards_bar
    &.ant-tabs-rtl
        .ant-tabs-nav
            .ant-tabs-nav-wrap
                .ant-tabs-nav-list
                    .ant-tabs-tab
                        margin: 0px 0 0 5px !important

    .ant-tabs-nav
        margin-bottom: 0
        .ant-tabs-nav-wrap
            .ant-tabs-nav-list
                padding-top: 0.5rem
                .ant-tabs-tab
                    background-color: #E5E5E5
                    border: 1px solid #B1B6C0
                    border-bottom: none
                    border-radius: 10px 10px 0px 0px
                    transition: all 300ms
                    &:hover
                        background-color: darken(#E5E5E5,5%)
                    .tab-link
                        padding: 0.5rem 1rem
                        color: #646D82
                        text-decoration: none

                    &.ant-tabs-tab-active
                        background-color: white
                        .tab-link
                            color: $theme-color

.ant-tabs-dropdown-menu-item
    .tab-link
        display: flex !important
        color: #4D4F5C
        text-decoration: none
        .link-icon
            svg
                width: 15px
