//bg colors
.bg-theme-color
    background-color: $theme-color !important
.bg-theme-color-filter
    background-color: #FCFCF5 !important
.bg-theme-color-900
    background-color: $theme-color-900 !important
.bg-gray
    background-color: #FBFBFB !important
.bg-salmon-400
    background-color: $salmon-400
.pattern-bg
    background-size: cover
    background-position: center center
