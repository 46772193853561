.dashboard-layout-wrapper
    overflow: hidden
    width: 100%
    height: 100vh
    display: flex
    flex-direction: column
    & > .header-wrapper
        width: 100%
        margin-bottom: 1rem
    .dashboard-layout
        width: 100%
        flex-grow: 1
        height: 1%
        display: flex
        .sidebar-wrapper
            height: 100%
        .main-containt-wrapper
            flex-grow: 1
            width: 1%
            height: 100%
            overflow-y: auto
            padding: 1rem
            padding-top: 0
            padding-right: 0

@media (max-width: 991px)
    .dashboard-layout-wrapper
        .dashboard-layout
            .main-containt-wrapper
                padding-right: 1rem
