.table-wrapper
    &.loading
        position: relative
        &::after
            content: 'Loading...'
            position: absolute
            top: 0
            left: 0
            bottom: 0
            right: 0
            background-color: rgba(white,0.95)
            display: flex
            justify-content: center
            align-items: center
            color: $theme-color
            font-weight: 500
            font-size: 1rem
    &.bordered
        border: 2px solid #eee
        border-radius: 10px
    &.scroll
        overflow-x: auto
        overflow-y: hidden
        .table
            min-width: 800px
    .table
        width: 100%
        text-align: center !important
        padding: 0 0.5rem
        vertical-align: middle
        border-collapse: separate
        border-spacing: 0 10px

        thead
            th
                vertical-align: middle
                // border-bottom: none
                border: none
                color: #3B0A80
                font-size: 16px
        tbody
            tr
                vertical-align: text-top
                box-shadow: 0px 0px 0px rgba(black,0.4)
                // transition: box-shadow 300ms
                color: #646D82
                font-size: 15px
                &.borderless
                    background-color: #FFF
                    border: none
                td
                    transition: all 300ms
                &.isDragging
                    box-shadow: 1px 1px 10px rgba(black,0.4)
                    background-color: white !important

                th
                    border: 1px solid $gray-400
                td
                    vertical-align: middle
                    &.divide
                        border-right: 2px solid rgba(#eee,0.3)
                    .icon
                        width: 15px
                        cursor: pointer
                    .img
                        width: 40px
                        max-height: 40px
                        object-fit: cover
                        border-radius: 8px

                &:first-child
                    td
                        border-top: none
                &:not(:first-child)
                    td
                        // border-top: 1px solid rgba($gray-500,0.4)
                        border-top: none
        &.striped
            tbody
                tr
                    td
                        border: 1px solid transparent
                    &:nth-child(even)
                        background-color: rgba($theme-color,0.035)
                    &:nth-child(odd)
                        background-color: white
        &.action
            tbody
                tr
                    background-color: transparent
                    transition: all 250ms
                    cursor: pointer
                tr:hover
                    background-color: rgba($theme-color,0.1)
                tr:active
                    background-color: rgba($theme-color,0.2)
        &.white-row-cards
            border-collapse: separate
            border-spacing: 0 0.6em
            thead
                th
                    border-bottom: none
                    border-top: none
                    padding-left: 0 !important
                    padding-right: 0 !important
            tbody
                tr
                    padding: 1rem 0
                    &.spacer
                        display: block
                        padding: 0.25rem 0
                    td
                        background-color: white
                        border-top: none
                        &:first-child
                            border-top-right-radius: 10px
                            border-bottom-right-radius: 10px
                        &:last-child
                            border-bottom-left-radius: 10px
                            border-top-left-radius: 10px
                            border-top-left-radius: 10px
                            border-bottom-left-radius: 10px
                        // &:last-child
                        //     border-bottom-right-radius: 10px
                        //     border-top-right-radius: 10px
.gray-table
    tbody
        tr
            background-color: #f7f7f7 !important

// @media (max-width: 1136px)
//     .table-wrapper
//         &.scroll
//             overflow-x: auto
//             overflow-y: hidden
