.anticon
    vertical-align: 0
.display-text-group
    .displayed-text
        white-space: pre-line
//text colors
.text-black
    color: black
.text-theme-color
    color: $theme-color
.text-theme-color-900
    color: $theme-color-900
.text-theme-color-400
    color: rgba($theme-color,0.7)

.text-gray-500
    color: $gray-500
.text-gray-900
    color: $gray-900
.text-gray-400
    color: $gray-400
.text-muted
    color: $gray-900 !important

//link colors

.link-theme-color,.read-more-button
    color: $theme-color
    text-decoration: none !important
    user-select: none
    cursor: pointer
    &:hover,&:focus
        color: darken($theme-color,10%)
.link-theme-color-900
    color: $theme-color-900
    text-decoration: none !important
    user-select: none
    cursor: pointer
    &:hover,&:focus
        color: darken($theme-color-900,10%)

.link-danger
    color: #F46363
    text-decoration: none !important
    user-select: none
    cursor: pointer
    &:hover,&:focus
        color: darken(#F46363,10%)

.link-gray-400
    color: $gray-400
    text-decoration: none !important
    user-select: none
    cursor: pointer
    &:hover,&:focus
        color: darken($gray-400,10%)
.link-gray-500
    color: $gray-500
    text-decoration: none !important
    user-select: none
    cursor: pointer
    &:hover,&:focus
        color: darken($gray-500,10%)
.link-gray-900
    color: $gray-900
    text-decoration: none !important
    user-select: none
    cursor: pointer
    &:hover,&:focus
        color: darken($gray-900,10%)
.link-white
    color: white
    text-decoration: none !important
    user-select: none
    cursor: pointer
    &:hover,&:focus
        color: darken(white,10%)


.text-dots
    display: block
    text-overflow: ellipsis
    overflow: hidden
    width: 100% 
    white-space: nowrap
.user-select-none
    user-select: none
    
.cursor-pointer
    cursor: pointer

//text fontweight
.weight-400
    font-weight: 400 !important
.weight-500
    font-weight: 500 !important
.weight-600
    font-weight: 600 !important
.weight-700
    font-weight: 700 !important

//text size
.font-Xsmall
    font-size: 11px !important
.font-small
    font-size: 12px !important
.font-Lsmall
    font-size: 14px !important
.font-medium
    font-size: 16px !important
.font-Lmedium
    font-size: 18px !important
.font-large
    font-size: 20px !important
.font-Xlarge
    font-size: 30px !important
@media (min-width: 1660px)
    .font-Xsmall
        font-size: 14px !important
    .font-small
        font-size: 16px !important
    .font-Lsmall
        font-size: 18px !important
    .font-medium
        font-size: 20px !important 
    .font-Lmedium
        font-size: 26px !important
    .font-large
        font-size: 28px !important
    .font-Xlarge
        font-size: 36px !important
@media (max-width: 600px)
    .font-Xsmall
        font-size: 9px !important
    .font-small
        font-size: 11px !important
    .font-Lsmall
        font-size: 12px !important
    .font-medium
        font-size: 14px !important
    .font-Lmedium
        font-size: 16px !important
    .font-large
        font-size: 18px !important
    .font-Xlarge
        font-size: 26px !important
@media (max-width: 420px)
    .font-Xsmall
        font-size: 7px !important
    .font-small
        font-size: 9px !important
    .font-Lsmall
        font-size: 10px !important
    .font-medium
        font-size: 12px !important
    .font-Lmedium
        font-size: 14px !important
    .font-large
        font-size: 16px !important
    .font-Xlarge
        font-size: 23px !important
@media (max-width: 350px)
    .font-Xsmall
        font-size: 6 !important
    .font-small
        font-size: 8 !important
    .font-Lsmall
        font-size: 9 !important
    .font-medium
        font-size: 11 !important
    .font-Lmedium
        font-size: 13 !important
    .font-large
        font-size: 15 !important
    .font-Xlarge
        font-size: 20 !important
@media (max-width: 335px)
    .font-Xsmall
        font-size: 5px !important
    .font-small
        font-size: 7px !important
    .font-Lsmall
        font-size: 8px !important
    .font-medium
        font-size: 10px !important
    .font-Lmedium
        font-size: 12px !important
    .font-large
        font-size: 14px !important
    .font-Xlarge
        font-size: 17px !important
