.fillters-wrapper
    .ant-input,.ant-input-group-wrapper,.ant-input-password,.ant-select,.ant-select-selection,.ant-input-number,.ant-picker,.ant-time-picker-input,.react-phone-number-input,.form-control,.ant-input-affix-wrapper
        border-radius: 20px
        border: 1px solid rgba(#bbbbbb,0.5)
        overflow: hidden
        height: 30px
        .ant-picker-suffix
            color: #bbbbbb
        .ant-select-selector
            border: none
            height: 100%
        .ant-input
            border: none
            border-left: 1px solid rgba(#bbbbbb,0.5)
        .ant-input-group-addon
            background-color: white
            .ant-input-search-button
                border: none
                background-color: transparent
                height: 25px
    .ant-input-affix-wrapper
        border: none
    .ant-select
        margin-bottom: -7px
.form-wrapper
    .form
        .form-input-wrapper
            position: relative
            margin-bottom: 1rem
            .input-label
                margin-bottom: 0.35rem
                user-select: none
                padding: 0 1rem
            .input-wrapper
                &.input-white-value
                    color: #FFF
                &.image-wrapper
                    position: relative
                    .uploaded-image
                        position: absolute
                        right: 20px
                        top: 3px
                    .upload-camera
                        position: absolute
                        left: 15px
                        top: 9px
                    .ant-upload
                        position: absolute
                        left: 11px
                        top: 9px
                        width: 40px
                        height: 25px
                        overflow: hidden
                        opacity: 0
                .ant-switch
                    &.ant-switch-checked
                        background-color: $theme-color
                .column-2-check-group
                    display: flex
                    flex-wrap: wrap
                    label.ant-checkbox-group-item.ant-checkbox-wrapper
                        min-width: 45%
                        .check-text
                            text-transform: lowercase
                        .check-text:first-letter
                            text-transform: capitalize
                .react-phone-number-input__row,.PhoneInput,.react-tel-input
                    height: 100%
                    width: 100%
                    padding: 0 !important
                    input
                        width: 100%
                    .flag-dropdown
                        background-color: transparent
                        border: none
                    .selected-flag
                        display: flex
                        justify-content: flex-start
                        .arrow
                            left: 20px
                .ant-radio-group
                    display: inline-flex
                    flex-direction: column
                    .ant-radio-button-wrapper
                        margin-bottom: 0.5rem
                        border: 1px solid $theme-color
                        border-radius: 5px
                        background-color: rgba($theme-color,0.1)
                        box-shadow: inset 0 0 0 0px rgba(79,176,174,0.8)
                        transition: all 300ms
                        color: $gray-500
                        &::before
                            display: none
                        &.ant-radio-button-wrapper-checked
                            background-color: rgba($theme-color,0.2)
                            // color: black
                            box-shadow: inset 0 0 0 2px rgba(79,176,174,0.8)
                .ant-checkbox-checked .ant-checkbox-inner
                    background-color: $theme-color
                    border-color: $theme-color
                .ant-radio-checked .ant-radio-inner
                    border-color: $theme-color
                    &::after
                        background-color: $theme-color

                &.white-input
                    .ant-input,.ant-input-password,.ant-select,.ant-select-selection,.ant-input-number,.ant-picker,.ant-time-picker-input,.react-phone-number-input,.form-control,.ant-input-affix-wrapper
                        background-color: white !important
                &.gray-input
                    .ant-input,.ant-input-password,.ant-select,.ant-select-selection,.ant-input-number,.ant-picker,.ant-time-picker-input,.react-phone-number-input,.form-control,.ant-input-affix-wrapper
                        background-color: #F9F8FB !important
                        border: none
                        padding: 1rem 1rem
                        .ant-select-selector
                            background-color: transparent
                            .ant-select-selection-overflow-item
                                .ant-select-selection-item
                                    background-color: #AD9FBF
                                    border-radius: 20px
                                    padding: 1rem 0.75rem
                                    color: white
                                    .ant-select-selection-item-remove
                                        width: 20px
                                        height: 20px
                                        border-radius: 50%
                                        border: 1.5px solid white
                                        display: flex
                                        align-items: center
                                        justify-content: center
                                        color: white
                &.big-padding-select
                     .ant-input,.ant-input-password,.ant-select,.ant-select-selection,.ant-input-number,.ant-picker,.ant-time-picker-input,.react-phone-number-input,.form-control,.ant-input-affix-wrapper
                        .ant-select-selector
                            .ant-select-selection-overflow-item
                                .ant-select-selection-item
                                    padding: 25px 15px
                                    
                .ant-input,.ant-input-password,.ant-select,.ant-select-selection,.ant-input-number,.ant-picker,.ant-time-picker-input,.react-phone-number-input,.form-control,.ant-input-affix-wrapper
                    width: 100%
                    border-radius: 10px
                    border: 1px solid #eee
                    min-height: 40px
                    font-weight: 500
                    transition: all 300ms
                    font-size: 15px
                    color: black
                    outline: none !important
                    box-shadow: none !important
                    #rfs-btn
                        border: none
                        outline: none !important
                        .ReactFlagsSelect-module_label__27pw9
                            padding: 0 0.5rem
                    .ReactFlagsSelect-module_selectOptions__3LNBJ
                        .ReactFlagsSelect-module_selectOption__3pcgW
                            .ReactFlagsSelect-module_label__27pw9
                                padding: 0 0.5rem
                    &:not(.ant-select)
                        background-color: #F5F7F9
                    &::placeholder
                        font-size: 13px
                        // color: rgba( black,0.7)
                    &:focus
                        border: 1px solid darken( #eee,20%)
                    .ant-input-number-handler-wrap
                        display: none
                    .ant-select-selector
                        border: none
                        box-shadow: none !important
                        background-color: white
                    .ant-select-selection-item
                        padding-right: 0.75rem
                        padding-left: 0.75rem
                        border-radius: 5px
                        background-color: white
                        font-weight: 500 !important
                        display: flex
                        align-items: center
                        .ant-select-selection-item-content
                            font-weight: 500 !important
                            .ant-select-selection-item-remove
                                // border: 1px solid #F46363
                                // border-radius: 50%
                                width: 15px
                                height: 15px
                                color: #F46363
                                display: inline-flex
                                justify-content: center
                                align-items: center
                                font-size: 14px

                    .ant-select-selector,.ant-select-selection__rendered,.ant-input-number-input-wrap
                        height: 100%
                        padding-left: 5px
                        padding-right: 5px
                        padding-top: 5px
                        padding-bottom: 5px
                        .ant-select-selection__choice
                            border-radius: 10px
                            background-color: rgba($gray-500,0.4)
                            border: 1px solid $gray-500
                            margin: 7px 3px
                        .ant-input-number-input
                            height: 100%
                        .ant-select-selection-selected-value
                            display: flex !important
                            align-items: center
                            height: 100%
                &:not(.input-underline)
                    .ant-input-affix-wrapper
                        input
                            border: none !important
                &.input-small
                    .ant-input,.ant-input-password,.ant-select,.ant-select-selection,.ant-input-number,.ant-picker,.ant-time-picker-input,.react-phone-number-input
                        height: 30px
                &.input-round
                    .ant-input,.ant-input-password,.ant-select,.ant-select-selection,.ant-input-number,.ant-picker,.ant-time-picker-input,.react-phone-number-input, .react-tel-input input,.ant-input-affix-wrapper
                        border-radius: 20px
                        padding-left: 1rem
                        padding-right: 1rem
                        // border: none
                &.input-underline
                    .react-phone-number-input__row,.PhoneInput,.react-tel-input
                        .flag-dropdown
                            border: none
                        input
                            border: none
                            border-bottom: 2px solid $gray-500
                            transition: all 300ms
                            border-radius: 0
                            &:focus
                                box-shadow: none
                                border-bottom: 2px solid $theme-color
                    .ant-input-password
                        border: none !important
                        box-shadow: none !important
                        padding: 0
                        align-items: end
                        input
                            padding-left: 0.5rem
                            padding-right: 0.5rem
                    .ant-input-group-addon
                        background-color: transparent
                        border: none
                        border-bottom: 2px solid $gray-500
                    .ant-input,.ant-select,.ant-select-selection,.ant-input-number,.ant-picker,.ant-time-picker-input,.react-phone-number-input
                        border: none
                        border-bottom: 2px solid $gray-500
                        transition: all 300ms
                        border-radius: 0
                        .ant-select-selector
                            border: none
                            box-shadow: none
                        &:focus,&.ant-picker-focused,&.ant-input-number-focused
                            box-shadow: none
                            border-bottom: 2px solid $theme-color
                        .ant-select-selection-item
                            padding-right: 0.75rem
                            padding-left: 0.75rem
                            border-radius: 20px
                            background-color: white
                    .ant-select-selection-placeholder
                        color: $theme-color
                        opacity: 0.9

@media (max-width: 767px)
    .form-wrapper
        .form
            .form-input-wrapper
                .input-wrapper
                    .ant-input,.ant-select-selection,.ant-input-number,.ant-time-picker-input,.react-phone-number-input
                        min-height: 35px
                        font-size: 13px
                        .ant-select-selection__rendered,.ant-input-number-input-wrap
                            .ant-select-selection__choice
                                margin: 4px 3px

@media (max-width: 420px)
    .form-wrapper
        .form
            .form-input-wrapper
                .input-wrapper
                    .ant-input,.ant-select-selection,.ant-input-number,.ant-time-picker-input,.react-phone-number-input
                        min-height: 30px
                        font-size: 12px
