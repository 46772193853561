.btn-badge
    display: inline-block
    padding: 0.25rem 0.5rem
    border-radius: 5px
    text-align: center
    user-select: none
    &.badge-dark-gray
        background-color: #646D82
        color: white
    &.badge-light-gray
        background-color: rgba(#646D82,0.3)
        color: black
    &.badge-theme-color
        background-color: rgba($theme-color,0.5)
        color: white

.round-badge
    display: inline-block
    padding: 0.25rem 0.5rem
    border-radius: 20px
    text-align: center
    user-select: none
    &.badge-theme-color
        background-color: rgba($theme-color,0.3)
        color: $theme-color
    &.badge-theme-color
        background-color: rgba($theme-color,0.3)
        color: $theme-color
    &.badge-danger-color
        background-color: rgba(#CB0505,0.3)
        color: #CB0505
    &.badge-secondary-800-color
        background-color: rgba($theme-color,0.9)
        color: white
    &.badge-gray
        background-color: #FAFAFA
        color: #767676
    &.badge-dark-gray-color
        background-color: #646D82
        color: white
    &.badge-warning-color
        background-color: rgba( #ffcc00,0.9)
        color: white
.secondary-color-tag
    display: flex
    color: white
    background-color: #9AD6CC
    padding: 0.65rem
    border-radius: 5px
    .text-wrapper
        white-space: nowrap
    .icon-wrapper
        padding-right: 0.2rem
        button
            padding: 0.2rem
            background-color: transparent
            border: none
            outline: none
            color: white
            cursor: pointer
            &:active
                color: $theme-color
