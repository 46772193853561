// .info-sidebar
//     .sidebar-cover-weapper
//         margin: 0 -0.5rem
//         margin-top: -0.25rem
//         border-radius: 5px 5px 0 0
//         overflow: hidden
//         // width: 100%
//         height: 130px
//         img
//             width: 100%
//             height: 100%
//             object-fit: cover
//     .badges-wrapper
//         display: flex
//         justify-content: center
//         flex-wrap: wrap
//     .side-badge
//         display: inline-block
//         padding: 0.5rem 0.75rem
//         border-radius: 5px
//         text-align: center
//         user-select: none
//         &.badge-dark-gray
//             background-color: #646D82
//             color: white
//         &.badge-theme-color
//             background-color: $secondary-color
//             color: white
//     .document-photo
//         display: block
//         width: 60px
//         height: 60px
//         border-radius: 5px
//         border: 0.25px solid rgba(#646D82,0.2)
//         &.document-photo-big
//             width: 80px
//             height: 80px
//         img
//             width: 100%
//             height: 100%
//             object-fit: cover
.stat-bar
    background-color: #F9F8FB
    border-radius: 5px
    padding: 0.5rem

    .divided
        border-left: 1px solid rgba(#646D82,0.3)

.icons-list-wrapper
    padding: 0 1rem
    .icons-list
        list-style: none
        padding: 0
        margin: 0
        li
            margin-bottom: 0.65rem
            display: flex
            align-items: center
            .icon-wrapper
                margin-left: 1rem
                width: 22px
                height: 22px
                display: flex
                justify-content: center
                align-items: center
                img
                    max-width: 90%
                    max-height: 90%
            .value-wrapper
                margin-left: 0.5rem
                flex-grow: 1
                width: 1%
