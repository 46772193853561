.tabs-theme-color
    display: flex
    .tab-link
        display: block
        background-color: #ECECEC
        color: #4D4F5C
        margin: 0 0.25rem
        padding: 0.5rem 1.25rem
        border-radius: 30px
        transition: all 250ms
        text-decoration: none !important
        &:hover
            background-color: darken(#ECECEC,5%)
        &.active
            background-color: $theme-color
            color: white

.pills-radio-wrapper
    .ant-radio-group
        .ant-radio-button-wrapper
            margin: 0 0.25rem
            border-radius: 20px !important
            background-color: #E5E5E5
            border: none
            color: $gray-500
            min-width: 90px
            text-align: center
            &.ant-radio-button-wrapper-checked
                background-color: $theme-color
                color: white !important
            &::before
                display: none
