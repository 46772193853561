$theme-color: #3B0A80
$theme-color-900: darken(#3B0A80,5%)
$secondary-color: #9AD6CC
//gray
$gray-400: #8392A5
$gray-500: #646D82
$gray-900: #3A3A3C

//salmon
$salmon-400: #f8f0db
